import type { PartnerProduct } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { CatalogServiceProductData } from 'src/services/catalog/types';

export const productModelToPartnerProduct = (
  {
    images,
    isNew,
    links,
    preSalePrices,
    prices,
    purchasable,
    salePercentage,
    title,
  }: CatalogServiceProductData,
  currency: CurrencyCode,
): PartnerProduct => {
  const price = prices.find((price) => price.currency === currency);
  /* istanbul ignore next */
  const preSalePrice = preSalePrices?.find((price) => price.currency === currency);

  return {
    alt: '',
    href: links[0]?.href /* istanbul ignore next */ || '',
    isNew: isNew,
    salePercentage: salePercentage ?? undefined,
    price: price ? price.amount : 0,
    purchasable: purchasable,
    preSalePrice: preSalePrice ? preSalePrice.amount : undefined,
    src: images[0]?.href /* istanbul ignore next */ || '',
    title: title,
  };
};
