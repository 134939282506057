import React from 'react';
import type {
  PartnerBlockProps,
  PartnerProfileData,
  PartnerProfileIndexes,
} from '@noths/polaris-client-ribbons-design-system';
import { PartnerBlock } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import { productModelToPartnerProduct } from 'src/content/sections/partnerBlock/modelToUI';
import type { PartnerModel } from 'src/content/sections/partnerBlock/types';
import type { CatalogServiceProductData } from 'src/services/catalog/types';
import { trackProductClick, trackProductImpression } from 'src/tracking/common/products';
import { transformProductModelToProductForTracking } from 'src/tracking/common/productTransformer';
import { trackItemsImpression } from 'src/tracking/common/trackItemsImpression';
import { trackLinkClick } from 'src/tracking/components/partnerBlock';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './PartnerBlockSection.style';

const hideNonPurchasableProducts = (products: CatalogServiceProductData[]) =>
  products.filter((product) => product.purchasable);

export interface PartnerBlockSectionProps extends Omit<PartnerBlockProps, 'partners'> {
  currency: CurrencyCode;
  pageType: string;
  partners: PartnerModel[];
  trackingPosition?: number;
  trackingTitle: string;
}

export const PartnerBlockSection = ({
  currency,
  pageType,
  partners,
  trackingPosition,
  trackingTitle,
  ...restOfProps
}: PartnerBlockSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingContentType: 'Partner block',
    trackingPosition,
    trackingTitle,
  };

  const partnersWithRenderedProducts = partners.map((partner) => ({
    ...partner,
    products: hideNonPurchasableProducts(partner.products),
  }));

  const partnersWithTransformedProducts = partnersWithRenderedProducts.map((partner) => ({
    ...partner,
    products: partner.products.map((product) => productModelToPartnerProduct(product, currency)),
  }));

  return (
    <SectionContainer wrapperStyles={styles.wrapper}>
      <PartnerBlock
        {...restOfProps}
        currency={currency}
        onPartnerClick={(
          e: React.MouseEvent<HTMLAnchorElement>,
          { partnerIndex }: { partnerIndex: number },
        ) => {
          trackLinkClick(e, {
            ...trackingContext,
            numberOfPartners: partnersWithRenderedProducts.length,
            partnerIndex,
            // get the partner name from the first product
            // assume both products are from the same partner
            partnerName: partnersWithRenderedProducts[partnerIndex].products[0].partner.name,
            partnerUrl: partnersWithRenderedProducts[partnerIndex].url,
          });
        }}
        onProductClick={(
          e: React.MouseEvent<HTMLAnchorElement>,
          { partnerIndex, productIndex }: PartnerProfileIndexes,
        ) => {
          if (partnersWithRenderedProducts[partnerIndex].products.length > 0) {
            trackProductClick(e, {
              ...trackingContext,
              pageType,
              numberOfItems: partnersWithRenderedProducts[partnerIndex].products.length,
              product: transformProductModelToProductForTracking(
                partnersWithRenderedProducts[partnerIndex].products[productIndex],
              ),
              productIndex,
            });
          }
        }}
        onProductVisible={({ partnerIndex }: { partnerIndex: number }) => {
          if (partnersWithRenderedProducts[partnerIndex].products.length > 0) {
            trackProductImpression({
              ...trackingContext,
              pageType,
              products: partnersWithRenderedProducts[partnerIndex].products.map(
                transformProductModelToProductForTracking,
              ),
            });
          }
        }}
        onVisible={() =>
          trackItemsImpression({
            ...trackingContext,
            numberOfItems: partnersWithRenderedProducts.length,
          })
        }
        partners={partnersWithTransformedProducts}
      />
    </SectionContainer>
  );
};

export type { PartnerProfileIndexes, PartnerProfileData };
